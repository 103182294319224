import React from 'react';
import ReactMarkdown from 'react-markdown';

const DescriptionText = () => {
  const text = `INFOPROD es una aplicación móvil desarrollada por el
        Ministerio de Desarrollo Productivo que permite acceder, de
        forma centralizada, a informes elaborados por las distintas
        áreas del organismo, visualizándolos de forma ágil.`;

  return <ReactMarkdown>{text}</ReactMarkdown>;
};

const DescriptionPage = () => {
  return (
    <div className="section description-page">
      <DescriptionText />
    </div>
  );
};

export default () => {
  return <DescriptionPage />;
};
