import _ from 'lodash';
import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CardContent from '../components/Card/Content';

export default ({ list, destination }) => {
  const searchList = _.filter(list, ({ destinations }) => destinations[0] === destination);
  return (
    <Container fluid>
      <Row>
        {_.map(searchList, (data, idx) => (
          <Col key={idx} md="12" xs="12">
            <CardContent content={data} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
