/* eslint-disable no-console */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './LoginRegisterPage.scss';

import CodeVerificationEmail from './CodeVerificationEmail';
import CodeVerificationPhone from './CodeVerificationPhone';
import { EMAIL_STATIS_PENDING, EMAIL_STATIS_VERIFIED } from '../constants';

export default ({ usr, onBack }) => {
  const [user, _setUser] = useState(usr);
  const setUser = data => _setUser(prev => ({ ...prev, ...data }));

  return (
    <Container>
      <Row className="panel">
        <Col lg={{ span: 6, offset: 3 }}>
          <Row className="title">
            <Col xs="12">Verifique sus datos</Col>
            <Col xs="12">
              <label className="required">&nbsp;</label>
            </Col>
          </Row>

          <Row className="row-data">
            {user.emailStatus === EMAIL_STATIS_PENDING && (
              <CodeVerificationEmail user={{ ...user }} onBack={onBack} setUser={setUser} />
            )}
            {user.emailStatus === EMAIL_STATIS_VERIFIED && user.phoneStatus === EMAIL_STATIS_PENDING && (
              <CodeVerificationPhone user={{ ...user }} onBack={onBack} setUser={setUser} />
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
