/* eslint-disable no-alert */
/* eslint-disable no-console */
import Event from './Event';
import socket from './socket';

const local = {
  firstTime: false
};

const initPN = () => {
  const { FirebasePlugin, device } = window;

  // Just for iOS devices.
  if (device.platform === 'iOS' && !local.firstTime) {
    local.firstTime = true;
    FirebasePlugin.grantPermission();
    FirebasePlugin.setBadgeNumber(0);
  }

  FirebasePlugin.getToken(
    token => {
      if (localStorage.devicePNToken !== token) {
        localStorage.devicePNToken = token;
        socket.emit('pn-on', localStorage.devicePNToken);
      }
    },
    error => {
      alert(error);
    }
  );

  FirebasePlugin.onTokenRefresh(
    token => {
      localStorage.devicePNToken = token;
      socket.emit('pn-on', localStorage.devicePNToken);
    },
    error => {
      alert(error);
    }
  );

  if (localStorage.devicePNToken) {
    socket.emit('pn-on', localStorage.devicePNToken);
  }
};

if (window.cordova) {
  Event.on('subscribe:success', initPN);
  Event.on('pn:logout', () => {
    if (localStorage.devicePNToken) {
      socket.emit('pn-off', localStorage.devicePNToken);
    }
  });
}
