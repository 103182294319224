/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

// import SwipeableTabs from 'react-swipeable-tabs';

import MdMenu from 'react-ionicons/lib/MdMenu';
import MdSearch from 'react-ionicons/lib/MdSearch';
import MdClose from 'react-ionicons/lib/MdClose';

import { views } from '../../constants';

import Visibility from '../../Store/Visibility';

import './Header.scss';

const strEmpty = str => !str || str === '';

const ifEnterDo = action => evt => evt.keyCode === 13 && action();

export default ({ moreCounter = {}, fire, destinations, destinationIdx, openMenu }) => {
  const [view, setView] = useState(views.default);
  const [search, setSearch] = useState();

  useEffect(() => {
    if (destinations && destinations.length > 0) {
      Visibility.changeDestinationVisible(0, destinations[0].title);
    }
  }, [destinations]);

  useEffect(() => {
    if (fire && destinations && destinations.length > 0 && destinationIdx !== undefined) {
      fire({ view: { id: view, search, destinations: destinations[destinationIdx].destination } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fire, view, destinationIdx, destinations]);

  useEffect(() => {
    if (fire && destinations && destinationIdx !== undefined && destinations[destinationIdx]) {
      fire({ view: { id: view, search, destination: destinations[destinationIdx].destination } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fire, view, destinations, destinationIdx]);

  useEffect(() => {
    closeSearch();
  }, [destinationIdx]);

  const goSearch = () => {
    if (search) {
      fire({ view: { id: view, search, destinations: destinations[destinationIdx].destination } });
      if (view !== views.search) {
        setView(views.search);
      }
    } else {
      setView(views.default);
      setSearch('');
    }
  };

  const closeSearch = () => {
    setView(views.default);
    setSearch('');
  };

  return (
    <Container className="app-header">
      <Row>
        <Col xs="12" className="header">
          <img className="main-logo" src="./logo_infoprod.png" alt="Infoprod" />

          <img className="logo" src="./logo.png" alt="logo" />
        </Col>
      </Row>

      <Row>
        <Col xs="12" className="menu-search">
          <div className="search">
            <Form.Control
              type="text"
              value={search}
              onChange={evt => setSearch(evt.target.value)}
              onKeyDown={ifEnterDo(goSearch)}
            />

            {!strEmpty(search) && (
              <Button onClick={closeSearch} variant="secondary">
                <MdClose color="#fff" />
              </Button>
            )}

            <Button disabled={strEmpty(search)} onClick={goSearch} variant="secondary">
              <MdSearch color="#fff" />
            </Button>
          </div>
          <Button variant="link" onClick={openMenu}>
            <MdMenu color="#000" fontSize="2rem" />
          </Button>
        </Col>
      </Row>

      {destinations && destinations.length > 0 && (
        <Row>
          <Col xs="12" className="destinations">
            <div className="tab-container">
              {_.map(destinations, ({ title, destination }, idx) => (
                <span
                  key={idx}
                  className={`tab-header-title ${destinationIdx === idx ? 'active' : ''}`}
                  onClick={() => Visibility.changeDestinationVisible(idx, title)}
                >
                  {title} {moreCounter[destination] > 0 && destinationIdx !== idx && <label className="has-unread" />}
                </span>
              ))}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};
