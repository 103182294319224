/* eslint-disable no-alert */
/* eslint-disable no-console */
import _ from 'lodash';
import React, { useState, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/es';

import './Card.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import IosArrowUp from 'react-ionicons/lib/IosArrowUp';
import IosLink from 'react-ionicons/lib/IosLink';
import IosDocumentOutline from 'react-ionicons/lib/IosDocumentOutline';
import LogoWhatsapp from 'react-ionicons/lib/LogoWhatsapp';

import Share from '../../services/Share';
import { TagsContext } from '../../Context/TagsContext';

const isMobile = process.env.REACT_APP_IS_MOBILE === 'true';

const isEmpty = text => text === undefined || text === null || text === '' || text === '<p></p>↵' || text.length < 9;

const ButtonsText = ({ content, visible, loading, setVisible, setLoading, textIsEmpty }) => {
  return (
    <>
      <div className="buttons-control">
        {!textIsEmpty && (
          <Button className="view-more link" variant="outline-primary" onClick={() => setVisible(!visible)}>
            {visible ? <IosArrowUp color="black" /> : <IosArrowDown color="black" />}
          </Button>
        )}

        {content.sourceLink && (
          <Button className="link" variant="outline-secondary" href={content.sourceLink} target="_blank">
            <IosLink color="black" />
          </Button>
        )}

        {content.attachFileName && (
          <Button className="link" variant="outline-secondary" href={content.attachFileName} target="_blank">
            <IosDocumentOutline color="black" />
          </Button>
        )}

        {!isMobile && (
          <Button
            className="link"
            variant="outline-secondary"
            onClick={async () => {
              setLoading(true);
              await Share.shareContentWeb(content);
              setLoading(false);
            }}
          >
            <LogoWhatsapp color="black" />
          </Button>
        )}

        {Share.hasWhatsapp() && (
          <Button
            disabled={loading}
            className="link"
            variant="outline-secondary"
            onClick={async () => {
              setLoading(true);
              await Share.shareContent(content);
              setLoading(false);
            }}
          >
            {loading ? <Spinner animation="border" variant="primary" size="sm" /> : <LogoWhatsapp color="black" />}
          </Button>
        )}
      </div>
    </>
  );
};

const Tag = ({ tagName, isSeparator, onClick }) => {
  return (
    <>
      <a href="#" onClick={onClick}>{`${tagName}`}</a>
      {isSeparator && <span> /</span>}
    </>
  );
};

const CardContent = ({ content, className = '' }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const textIsEmpty = isEmpty(content.text);
  const hasImage = content.imageFileName;
  const { setTags } = useContext(TagsContext);

  const handleTagClick = tagName => {
    setTags([tagName]);
  };

  return (
    <>
      <Card className={`${className}${content.children && content.children.length > 0 ? ' parent' : ''}`}>
        <div className={visible ? 'card-wrapper full' : 'card-wrapper'}>
          <Row className="card-wrapper__block">
            {hasImage && (
              <Col xs="12" lg="4" className="image-wrapper">
                <div className="img-container">
                  <Card.Img variant="top" src={content.imageFileName} />
                </div>
              </Col>
            )}

            <Col xs="12" lg={hasImage ? '8' : '12'}>
              <Card.Body>
                <Row className="card-wrapper__content">
                  <div className="content-wrapper">
                    <div className="card-header-content">
                      {content.title && (
                        <div className="card-title-header">
                          {content.title}
                          <div className="datetime-content">{`${moment(content.dateTime).format('DD/MM/YY')}`}</div>
                        </div>
                      )}

                      {content.tags && content.tags.length > 0 && (
                        <Card.Title className="tags">
                          <ul>
                            {content.tags.map((tag, index) => (
                              <li key={`${tag}tag`}>
                                <Tag
                                  isSeparator={content.tags.length - 1 != index}
                                  tagName={tag}
                                  onClick={() => handleTagClick(tag)}
                                />
                              </li>
                            ))}
                          </ul>
                        </Card.Title>
                      )}

                      {content.summary && (
                        <Card.Text
                          className={true ? 'summary full' : 'summary'}
                          dangerouslySetInnerHTML={{
                            __html: content.summary
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <ButtonsText {...{ content, visible, loading, setVisible, setLoading, textIsEmpty }} />
                </Row>
              </Card.Body>
            </Col>
          </Row>
        </div>

        {visible && !textIsEmpty && (
          <div className="card-text-full">
            <div>
              <Card.Text
                className="complete-text"
                dangerouslySetInnerHTML={{
                  __html: content.text
                }}
              />
            </div>
          </div>
        )}
      </Card>
      {_.map(content.children, (dataChild, key) => (
        <CardContent
          key={key}
          content={dataChild}
          className={`child${content.children.length - 1 === key ? ' last' : ''}`}
        />
      ))}
    </>
  );
};

export default CardContent;
