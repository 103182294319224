/* eslint-disable no-console */
import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import './LandingPage.scss';
import FirstPage from './landing/FirstPage';
import DescriptionPage from './landing/DescriptionPage';
import AdvantagePage from './landing/AdvantagePage';
import InstallationPage from './landing/InstallationPage';
import LastPage from './landing/LastPage';

const BASE_PATH_ASSETS = `${window.location.origin}/assets`;

export default () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Container fluid className="landing-container">
      <FirstPage BASE_PATH_ASSETS={BASE_PATH_ASSETS} />
      <DescriptionPage />
      <AdvantagePage BASE_PATH_ASSETS={BASE_PATH_ASSETS} />
      <InstallationPage BASE_PATH_ASSETS={BASE_PATH_ASSETS} />
      <LastPage BASE_PATH_ASSETS={BASE_PATH_ASSETS} />
    </Container>
  );
};
