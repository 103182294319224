/* eslint-disable no-alert */
/* eslint-disable no-console */
import _ from 'lodash';
import axios from 'axios';

const SHOW_IMAGE = true;

const mimeTypes = {
  jpg: 'image/jpg',
  png: 'image/png',
  jpeg: 'image/jpg',
  default: 'image/png'
};

const getExtension = url => {
  const urlSplitted = url.split('.');
  return _.last(urlSplitted);
};

const getFileFromUrl = async url => {
  const extension = getExtension(url);
  const mimeType = mimeTypes[extension] || mimeTypes.default;
  const fileName = `${new Date().getTime()}.${extension}`;
  const config = { responseType: 'blob' };

  const response = await axios.get(url, config);

  const blob = new Blob([response.data], { type: mimeType });
  return { blob, fileName };
};

const saveFile = async (fileName, blob) =>
  new Promise((resolve, reject) => {
    const { cacheDirectory } = window.cordova.file;
    const onSuccess = ({ target: { localURL } }) => {
      window.resolveLocalFileSystemURL(localURL, entry => {
        resolve(entry.toURL());
      });
    };

    const onError = error => {
      reject(error);
    };

    window.resolveLocalFileSystemURL(cacheDirectory, fs => {
      if (!fs) {
        return onError('Not exist FS');
      }

      fs.getFile(
        fileName,
        { create: true, exclusive: true },
        fileEntry => {
          fileEntry.createWriter(fileWriter => {
            fileWriter.onwriteend = onSuccess;
            fileWriter.onerror = onError;
            fileWriter.write(blob);
          }, onError);
        },
        onError
      );
    });
  });

const persistImg = async url => {
  const { fileName, blob } = await getFileFromUrl(url);
  return saveFile(fileName, blob);
};

const shareContent = async content => {
  const { device } = window;

  const printError = error => {
    // alert(JSON.stringify(error, null, 2));
    console.error(error);
    alert('No se puede enviar por Whatsapp');
  };

  if (_.get(window, 'plugins.socialsharing.shareViaWhatsApp')) {
    try {
      const tags = _.map(content.tags, tag => tag.toUpperCase());
      const summary = (content.summary || '')
        .replaceAll('<p>', '\n')
        .replaceAll('<br>', '\n')
        .replaceAll('<li>', '\n')
        .replace(/<[^>]*>/g, '')
        .trim();
      const text = (content.text || '')
        .replaceAll('<p>', '\n')
        .replaceAll('<br>', '\n')
        .replaceAll('<li>', '\n')
        .replace(/<[^>]*>/g, '')
        .trim();

      const image =
        content.imageFileName && SHOW_IMAGE && device.platform !== 'iOS'
          ? await persistImg(content.imageFileName)
          : undefined;

      window.plugins.socialsharing.shareViaWhatsApp(
        `📌 *${tags.join(' / ')}*\n*${content.medium ? content.medium.name : ''}* - *${content.title}*\n${
          summary ? `\n${summary}` : ''
        }${text ? `\n\n${text}` : ''}`,
        image,
        content.sourceLink ? content.sourceLink : null,
        () => console.log('share ok'),
        printError
      );
    } catch (err) {
      printError(err);
    }
  } else {
    printError('no hay socialsharing');
  }
};

const shareContentWeb = async content => {
  // const tags = _.map(content.tags, tag => tag.toUpperCase());
  const summary = (content.summary || '')
    .replaceAll('<p>', '%0D%0A')
    .replaceAll('<br>', '%0D%0A')
    .replaceAll('<li>', '%0D%0A')
    .replace(/<[^>]*>/g, '')
    .trim();
  const text = (content.text || '')
    .replaceAll('<p>', '%0D%0A')
    .replaceAll('<br>', '%0D%0A')
    .replaceAll('<li>', '%0D%0A')
    .replace(/<[^>]*>/g, '')
    .trim();
  /*
  const message = `📌 *${tags.join(' / ')}*
  *${content.medium ? content.medium.name : ''}* - *${content.title}*
  ${summary ? `%0D%0A${summary}` : ''}
  ${text ? `%0D%0A${text}` : ''}
  ${content.sourceLink ? `%0D%0A${content.sourceLink}` : ''}
  `;
*/
  const message = `*${content.title}* (${content.destinations[0]})
  ${summary ? `%0D%0A${summary}` : ''}
  ${text ? `%0D%0A${text}` : ''}
  ${content.sourceLink ? `%0D%0A${content.sourceLink}` : ''}
  `;
  window.open(`https://api.whatsapp.com/send?text=${message.replaceAll('\n', '')}`);
};

export default {
  hasWhatsapp: () => {
    return _.get(window, 'plugins.socialsharing.shareViaWhatsApp') !== undefined;
  },
  shareContent,
  shareContentWeb
};
