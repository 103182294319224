/* eslint-disable no-console */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { navigate, addNotification } from '../services';
import CodeAction from '../Store/Code';
import { Validators, Validate } from '../utils/Validations';

import './LoginRegisterPage.scss';

const labels = {
  password: 'Contraseña'
};

export default () => {
  const [user, _setUser] = useState({});

  const setUser = data => _setUser(prev => ({ ...prev, ...data }));

  const sendCodeResetPassword = async () => {
    const res = await CodeAction.createCodeResetPassword(user.username);
    setUser({ email: res.email });
  };
  const validatePasswords = async (user, labels) => {
    const validation = {
      password: Validators.sequential([Validators.required(), Validators.password()])
    };
    return Validate(user, validation, labels);
  };

  const validateCodeResetPassword = async () => {
    const validation = await validatePasswords(user, labels);
    if (validation.password) {
      addNotification({ message: validation.password[0] });
      return;
    }
    CodeAction.validateCodeResetPassword(user.username, user.code, user.password).then(() => {
      navigate('/login');
    });
  };
  const hideEmail = function (email) {
    return email.replace(/(.{3})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += '*';
      }
      return gp2;
    });
  };

  return (
    <Container>
      <Row className="panel">
        <Col lg={{ span: 6, offset: 3 }}>
          <Row className="title">
            <Col xs="12">Olvidé mi Contraseña</Col>
            <Col xs="12">
              <label className="required">&nbsp;</label>
            </Col>
          </Row>

          <Row className="row-data">
            <Col xs="12">
              <Form.Group controlId="formGroupEmail">
                <Form.Label>Usuario *</Form.Label>
                <Form.Control
                  type="text"
                  value={user.username}
                  onChange={evt => setUser({ username: evt.target.value })}
                />
                <Form.Label>
                  {user && user.email && <small> se ha enviado un email a {hideEmail(user.email)}</small>}
                </Form.Label>
              </Form.Group>
            </Col>

            <Col xs="12">
              <Row>
                <Col xs="12">
                  <Button variant="primary" type="button" onClick={sendCodeResetPassword} className="btn-code">
                    Enviar Codigo al Email
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col xs="12">
              <Form.Group controlId="formGroupEmail">
                <Form.Label>Código de Email *</Form.Label>
                <br />
                <Form.Label>
                  <small>Recibirá por email el código a verificar</small>
                </Form.Label>
                <Form.Control type="number" value={user.code} onChange={evt => setUser({ code: evt.target.value })} />
              </Form.Group>
            </Col>

            <Col xs="12">
              <Form.Group controlId="formGroupEmail">
                <Form.Label>Contraseña *</Form.Label>
                <Form.Control
                  type="password"
                  value={user.password}
                  onChange={evt => setUser({ password: evt.target.value })}
                />
              </Form.Group>
            </Col>

            <Col xs="12">
              <Row>
                <Col xs="12">
                  <Button variant="success" type="button" onClick={validateCodeResetPassword} className="btn-code">
                    Cambiar Contraseña
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col xs="12">
              <Row>
                <Col xs="12">
                  <Button variant="secondary" type="button" onClick={() => window.history.back()} className="btn-code">
                    Volver
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
