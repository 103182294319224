import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';

import { Store } from '../../Store';

export default props => {
  const { state } = useContext(Store);

  const IOSText = () => {
    // const text = `En el caso de iOS, una vez instalada la app, debe ser habilitada
    //     desde: Conﬁguración > General > Adm. de dispositivos >
    //     Subsecretaría de Gobierno Digital > Conﬁar en "Subsecretaría
    //     de Gobierno Digital"`;
    const text = `En el caso de iOS, una vez instalada la app, debe ser habilitada
        desde: Conﬁguración > General > Adm. de dispositivos >
        Subsecretaría de Gobierno Digital > Conﬁar en "Subsecretaría
        de Gobierno Digital"`;

    return <ReactMarkdown>{text}</ReactMarkdown>;
  };

  const LastPage = props => {
    const handleIOS = () => {
      window.location.href = state?.version?.linkIOS || process.env.REACT_APP_LINK_DOWNLOAD_IOS;
    };

    const handleAndroid = () => {
      window.location.href = state?.version?.linkAndroid || process.env.REACT_APP_LINK_DOWNLOAD_ANDROID;
    };

    return (
      <div id="download-app" className="last-page">
        <div className="section">
          <div className="title-container">
            <h3 className="title"> LINKS DE DESCARGA</h3>
          </div>
          <div className="download-container">
            <div className="android">
              <button className="button-image" onClick={handleAndroid}>
                <img alt="descargar en android" src={`${props.BASE_PATH_ASSETS}/img/icono-android.svg`} />
              </button>
            </div>
            <div className="ios">
              <button className="button-image" onClick={handleIOS}>
                <img alt="descargar en ios" src={`${props.BASE_PATH_ASSETS}/img/icono-ios.svg`} />
              </button>
            </div>
          </div>
          <div className="ios-text-container">
            <div className="text">
              <img alt="descargar en ios" src={`${props.BASE_PATH_ASSETS}/img/icono-alerta.png`} />
              <IOSText />
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="left">
            <img alt="Argentina Unida" src={`${props.BASE_PATH_ASSETS}/img/LogoArgentinaUnida.png`} />
          </div>
          <div className="right">
            <img alt="Escudo nacional argentino" src={`${props.BASE_PATH_ASSETS}/img/EscudoFooter.png`} />
          </div>
        </div>
      </div>
    );
  };
  return <LastPage BASE_PATH_ASSETS={props.BASE_PATH_ASSETS} />;
};
