import _ from 'lodash';
import React, { useContext, useState, useCallback, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';

import Container from 'react-bootstrap/Container';

import { ModalFooter } from 'react-bootstrap';
import AppContainer from '../components/visibility/AppContainer';

import { Store } from '../Store';
import PublishedActions from '../Store/Published';
import AuthAction from '../Store/Auth';

import MenuPanel from '../panel/MenuPanel/Menu';
import HeaderPanel from '../panel/HeaderPanel/Header';
import SearchPanel from '../panel/SearchPanel';

import { useAction } from '../utils/Hooks';
import { views } from '../constants';

import { getDestinations, processSearchView } from '../services/view';
import { navigate } from '../services';

import './HomePage.scss';
import { TagsContext } from '../Context/TagsContext';

function App() {
  const [viewMenu, setViewMenu] = useState(false);
  const [visibilityStatus, setVisibilityStatus] = useState(true);
  const [action, _setAction] = useState({});
  const [userDestinations, setUserDestinations] = useState([]);
  const setAction = useCallback(attrs => _setAction(prev => ({ ...prev, ...attrs })), []);

  const { state } = useContext(Store);
  const { data: destinations } = useAction(getDestinations);
  const searchResult = useAction(processSearchView, action);

  const { tags, setTags } = useContext(TagsContext);

  useEffect(() => {
    AuthAction.syncLoggedIn().catch(() => {
      navigate('/landing');
    });
  }, []);

  useEffect(() => {
    getUserDestination();
  }, [state.user]);

  const loadMore = useCallback((destination, id) => {
    return PublishedActions.loadMore(destination, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserDestination = () => {
    const userDest = state.user?.destinations || [];
    const userDestinations = _.filter(destinations, ({ title }) => userDest.includes(title));
    // _.each(userDestinations, ({ destination }) => {
    //   PublishedActions.getUnreadList(destination);
    // });
    setUserDestinations(userDestinations);
  };
  const includeTag = (tags, tag) => {
    let isInclude = false;
    tags.forEach(_tag => {
      if (_tag === tag[0]) {
        isInclude = true;
      }
    });
    return isInclude;
  };

  const Tag = ({ title }) => {
    const handleOnClose = () => {
      setTags([]);
    };
    return (
      <div className="tag">
        <span>{title.toUpperCase()}</span>
        <button onClick={handleOnClose}>
          <img src="./assets/img/DeleteIcon.svg" />
        </button>
      </div>
    );
  };
  return (
    <AppContainer onVisibilityChange={setVisibilityStatus}>
      <Modal
        show={viewMenu}
        onHide={() => setViewMenu(false)}
        dialogClassName="modal-90w modal-full-h"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <MenuPanel user={state.user} />
        </Modal.Body>
        <ModalFooter>
          <div className="copyright-container">
            <p>Copyright 2020 - Presidencia de la Nación Argentina</p>
            <p>Todos los derechos reservados</p>
          </div>
        </ModalFooter>
      </Modal>

      <HeaderPanel
        destinations={userDestinations}
        moreCounter={state.published.moreCounter}
        fire={setAction}
        openMenu={() => setViewMenu(true)}
        destinationIdx={state.visibility.destinationVisible}
      />

      <Container className="home-page-container">
        {tags.length > 0 && <Tag title={tags[0]} />}
        {/* CONTENTS */}
        {action.view && action.view.id === views.default && action.view.destination && (
          <>
            <Tab.Container activeKey={action.view.destination}>
              <Tab.Content>
                {_.map(userDestinations, ({ destination, Component }) => {
                  console.log(state.published.list[destination]);
                  return (
                    <Tab.Pane key={destination} eventKey={destination}>
                      <Component
                        destination={destination}
                        fire={setAction}
                        tags={tags}
                        list={
                          state.published.list[destination] && tags.length > 0
                            ? state.published.list[destination].filter(data => includeTag(data.data.tags, tags))
                            : state.published.list[destination]
                        }
                        moreCounter={state.published.moreCounter[destination]}
                        onLoadMore={loadMore}
                        isVisible={action.view.destination === destination && visibilityStatus}
                      />
                    </Tab.Pane>
                  );
                })}
              </Tab.Content>
            </Tab.Container>
          </>
        )}

        {/* SEARCH */}
        {action.view && action.view.id === views.search && (
          <>
            {searchResult.loading && (
              <div className="loading-indicator">
                <span className="loader" />
                <p className="loading-indicator__text">Cargando</p>
              </div>
            )}

            {searchResult.data && (
              <div className="tab-content">
                <SearchPanel
                  destination={userDestinations[state.visibility.destinationVisible].destination}
                  list={
                    searchResult.data && tags.length > 0
                      ? searchResult.data.filter(data => includeTag(data.tags, tags))
                      : searchResult.data
                  }
                />
              </div>
            )}

            {searchResult.error && <div>{searchResult.error}</div>}
          </>
        )}
      </Container>
    </AppContainer>
  );
}

export default App;
