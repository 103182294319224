import React, { useEffect } from 'react';

import './terms.scss';

export default () => {
  /** Reset to the top scroll  */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="legal">
  <h2>Protección de Datos personales - Política de privacidad</h2>
  <p>El
  Ministerio cumple con lo establecido en la Ley N° 25.326 y normativa
  complementaria. El Ministerio cumple con todos los principios que
  surgen de la Normativa de Protección de Datos Personales, que
  incluyen pero no se limitan a:</p>
  <ol>
    <li/>
  <p>principio
    de legalidad ya que los datos recolectados mediante el uso de la
    Aplicación se encuentran debidamente registrados. 
    </p>
    <li/>
  <p>principio
    de calidad dado que el Ministerio recolecta aquellos datos
    personales que resultan necesarios y adecuados para el uso de la
    Aplicación y lo realiza a través de medios legales y respetando la
    intimidad de los titulares de dichos datos personales. 
    </p>
    <li/>
  <p>principio
    de finalidad ya que el Ministerio utiliza los datos personales
    recabados únicamente para el fin para el que fueron recolectados. 
    </p>
    <li/>
  <p>principio
    de consentimiento informado, dado que el Ministerio recaba el
    consentimiento de los titulares de los datos personales en la forma
    determinada en los Términos y Condiciones. 
    </p>
    <li/>
  <p>principios
    de seguridad y confidencialidad de la información, mediante la
    utilización de tecnología que permite resguardar adecuadamente
    –según el estado de la técnica- la confidencialidad y seguridad
    de la información. Asimismo, para garantizar la seguridad de los
    datos, el Ministerio aplicará los mismos criterios y el mismo grado
    de diligencia que aplica para resguardar su propia información. 
    </p>
  </ol>
  <p>Los
  datos proporcionados por los Usuarios tendrán por finalidad:</p>
  <ul>
    <li/>
  <p>Habilitar
    los registros de usuario por parte del Administrador.</p>
    <li/>
  <p>Obtener
    métricas de uso y niveles de servicio de la Aplicación. 
    </p>
  </ul>
  
  <p>El
  formulario de inscripción que deben completar los Usuarios para
  registrarse puede solicitar campos obligatorios que contengan los
  siguientes datos personales:</p>

  <ul>
    <li/>
  <p>Nombres</p>
    <li/>
  <p>Apellidos</p>
    <li/>
  <p>Número
    de DNI, CUIL o Pasaporte</p>
    <li/>
  <p>Dirección
    de correo electrónico</p>
    <li/>
  <p>Fecha
    de nacimiento</p>
    <li/>
  <p>Nacionalidad</p>
    <li/>
  <p>Provincia</p>
    <li/>
  <p>Localidad</p>
    <li/>
  <p>Teléfono
    móvil</p>
    <li/>
  <p>Dirección</p>
    <li/>
  <p>Código
    postal</p>
  </ul>

  <h3><a name="_heading=h.4d34og8"></a>
  Datos
  sobre el dispositivo</h3>
  <p>Es
  posible que el Ministerio deba recoger datos específicos sobre el
  dispositivo del usuario como, por ejemplo, el modelo de su equipo, la
  versión del sistema operativo, los identificadores únicos.
  Asimismo, el Ministerio podrá asociar los identificadores del
  dispositivo o número de teléfono del usuario con su cuenta en el
  sistema de identificación único.</p>
  <p>Es
  posible utilizar diferentes tecnologías para recoger y almacenar
  datos cuando el usuario accede a la Aplicación, incluyendo el envío
  de una o varias cookies o de identificadores anónimos a su
  dispositivo.</p>
  
  <h3><a name="_heading=h.2s8eyo1"></a>
  Datos
  de Registro</h3>
  <p>El
  Ministerio podrá obtener y almacenar automáticamente determinada
  información en sus registros cada vez que los Usuarios utilicen la
  Aplicación o consulten sus contenidos.</p>
  <p>Dicha
  información incluye:</p>
  <ol>
    <li/>
  <p>Información
    detallada sobre la utilización de la Aplicación. 
    </p>
    <li/>
  <p>Dirección
    IP.</p>
    <li/>
  <p>Cookies,
    datos que permitirán identificar el navegador o cuenta de los
    Usuarios en el sistema de identificación único.</p>
    <li/>
  <p>Datos
    sobre la ubicación física de los Usuarios.</p>
  </ol>
  
  <p>Al
  utilizar un activo digital que pueda registrar la ubicación física
  de los Usuarios, el Ministerio puede recolectar y tratar datos acerca
  de su ubicación real. Estos datos son almacenados en forma anónima.</p>
  <h3><a name="_heading=h.17dp8vu"></a>
  Cookies
  e identificadores anónimos</h3>
  <p>El
  Ministerio utiliza diferentes tecnologías para recoger y almacenar
  datos cuando los usuarios acceden a la Aplicación, incluyendo el
  envío de una o varias cookies o de identificadores anónimos al
  dispositivo de los Usuarios.</p>
  <p>La
  vinculación entre los datos mencionados y la información que con el
  transcurso del tiempo se genere en el sistema sobre los Usuarios
  acerca de la información consultada, conforman un perfil del
  usuario.</p>
  <h3><a name="_heading=h.3rdcrjn"></a>
  Datos
  recolectados o provistos por terceros</h3>
  <p>Google
  Analytics</p>
  <p>El
  Ministerio recolecta información estadística anónima para la
  evaluación y mejora del servicio a través de un servicio de
  terceros (Google Analytics).</p>
  <p>El
  Ministerio utiliza cookies para almacenar parte de esta información
  en los dispositivos de los Usuarios.</p>
  <p>En
  el siguiente enlace puede consultar los Términos de Uso de Google
  Analytics: http://www.google.com/analytics/terms/us.html.</p>
  <p>Muchos
  de los servicios puestos a disposición permiten que los Usuarios
  compartan información entre sí. A su vez, el usuario puede vincular
  su cuenta en el sistema de identificación único con sus perfiles en
  redes sociales.</p>
  
  <h3><a name="_heading=h.26in1rg"></a>
  Seguridad</h3>
  <p>El
  Ministerio adopta las medidas técnicas y organizativas que resulten
  necesarias para garantizar la seguridad y confidencialidad de los
  datos personales de modo de evitar su tratamiento no autorizado.</p>
  <p>Para
  proteger a los datos de la Aplicación y a los Usuarios de cualquier
  modificación, divulgación o destrucción no autorizada de los
  mismos se toman, entre otros, los siguientes recaudos:</p>
  <ul>
    <li/>
  <p>Entorno
    seguro para el equipamiento y los sistemas utilizados para almacenar
    los datos de los usuarios.</p>
    <li/>
  <p>Políticas
    definidas en materia de recolección, almacenamiento y tratamiento
    de datos de registro de usuario y uso, incluyendo medidas de
    seguridad física y controles de acceso a los sistemas que emplean
    los activos digitales.</p>
    <li/>
  <p>Acceso
    restrictivo a los datos por parte de terceros, basados en el
    principio de “necesidad de saber” y una adecuada separación de
    funciones, asegurando la suscripción y el cumplimiento de las
    condiciones de confidencialidad requeridas por los datos bajo
    tratamiento. 
    </p>
    <li/>
  <p>Otras
    medidas de seguridad que resulten de aplicación en cada caso,
    conforme a la normativa vigente.</p>
  </ul>
  
  <p>Los
  datos personales que los Usuarios suministren a través de la
  Aplicación, como así también los que la Aplicación recolecte en
  forma automatizada serán almacenados en una base de datos
  cuyo responsable es el Ministerio con domicilio en Av. Pte. Julio A.
  Roca 651 de la Ciudad Autónoma de Buenos Aires. 
  </p>
  
  <p>En
  caso de dudas en relación a las Políticas de privacidad detalladas
  en los Términos y condiciones, se sugiere  ponerse en contacto con
  el Ministerio a través de <a href="mailto:infoprod@produccion.gob.ar"><u>infoprod@produccion.gob.ar</u></a>.</p>

  <h3>Modificaciones</h3>
  <p>El
  Ministerio se reserva el derecho de modificar en cualquier momento la
  presente Política de Privacidad, comprometiéndose a anunciarlo por
  medio de un aviso en el activo digital que corresponda.</p>

  <p>El
  Ministerio no controla ni es responsable por accionar de otros sitios
  web y otros productos y servicios a los cuales se puede acceder por
  medio de los Servicios Digitales, por lo que recomienda la lectura de
  la Política de Privacidad de cada uno de ellos.
  </p>
  <p>Notificaciones
  y comunicaciones 
  </p>
  <p>Se
  considerarán eficaces las comunicaciones que consistan en avisos y
  mensajes insertos en la Aplicación que tengan por finalidad informar
  a los Usuarios sobre determinada circunstancia, como por ejemplo, un
  cambio en los Términos y condiciones o en la Política de Privacidad.</p>
 
  <h3>Derecho
  aplicable y Jurisdicción</h3>
  <p>En
  relación con esta Política de Privacidad rigen las disposiciones de
  la Ley Nº 25.326 de Protección de Datos Personales y sus
  normas complementarias y demás normativa de la República Argentina.</p>
  <p>La
  Autoridad de Aplicación es la Dirección Nacional de Protección de
  Datos Personales, dependiente de la Agencia de Derecho de Acceso a la
  Información Pública, órgano descentralizado en el ámbito de
  Jefatura de Gabinete de Ministros. 
  </p>
  <p>En
  caso de surgir cualquier controversia respecto de la interpretación
  o cumplimiento de la presente, la misma será dirimida por los
  Tribunales en lo Contencioso Administrativo Federal con asiento en la
  Ciudad Autónoma de Buenos Aires.</p>
    </div>
  );
};
