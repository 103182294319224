import React, { createContext, useState } from 'react';

const TagsContext = createContext();

const initialStateTags = [];

const TagsContextProvider = ({ children }) => {
  const [tags, setTags] = useState(initialStateTags);
  return <TagsContext.Provider value={{ tags, setTags }}>{children}</TagsContext.Provider>;
};

export { TagsContext, TagsContextProvider };
