/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { navigate } from '../services';
import AuthAction from '../Store/Auth';
import CodeVerificationPage from './CodeVerificationPage';

import { FormContainer, FormContainerCell, FormGroup } from '../components/Form';
import { validateRegister } from './LoginRegisterValidations';

import './LoginRegisterPage.scss';

const labels = {
  firstName: {
    label: 'Nombre',
    errorName: 'Nombre'
  },
  lastName: {
    label: 'Apellido',
    errorName: 'Apellido'
  },
  username: {
    label: 'Usuario',
    errorName: 'Usuario'
  },
  password: {
    label: 'Contraseña',
    errorName: 'Contraseña'
  },
  passwordRepeat: {
    label: 'Repetir Contraseña',
    errorName: 'Repetir Contraseña'
  },
  boss: {
    label: 'Autoridad Superior',
    errorName: 'Autoridad Superior'
  },
  email: {
    label: 'Email',
    errorName: 'Email'
  },
  phone: {
    label: 'Teléfono: (Cod. área sin el cero + número) sin espacios',
    errorName: 'Número de teléfono'
  }
};
const legends = {
  phone: 'Ej.: Real (011) 15-2233-4455 --> Escribir 1122334455'
};

export default () => {
  const getFormLabels = labels => {
    const keys = Object.keys(labels);
    const result = {};
    keys.forEach(key => (result[key] = labels[key].label));
    return result;
  };
  const getErrorNames = labels => {
    const keys = Object.keys(labels);
    const result = {};
    keys.forEach(key => (result[key] = labels[key].errorName));
    return result;
  };
  const [user, _setUser] = useState({ username: '', email: '', phone: '' });
  const [error, setError] = useState({});
  const [formLabels] = useState(getFormLabels(labels));
  const [errorNames] = useState(getErrorNames(labels));
  const [isDisabledRegister, setIsDisabledRegister] = useState(false);
  const [userToVerify, setUserToVerify] = useState();
  const setUser = useCallback((data = {}) => {
    setError({});
    _setUser(prev => ({ ...prev, ...data }));
  }, []);

  useEffect(() => {
    AuthAction.syncLoggedIn({ stayPage: true });
  }, []);

  const register = useCallback(async () => {
    const { firstName, lastName, username, password, boss, email, phone } = user;
    const res = await AuthAction.register({ firstName, lastName, username, password, boss, email, phone });
    setError(res);
    if (res.data) {
      setUserToVerify(res.data);
    }
    setIsDisabledRegister(false);
  }, [user, isDisabledRegister]);

  useEffect(() => {
    if (isDisabledRegister) {
      register();
    }
  }, [user, isDisabledRegister]);

  const goRegister = async () => {
    try {
      const resultValidation = await validateRegister(user, errorNames);
      if (!_.isEmpty(resultValidation)) {
        setError(resultValidation);
        setIsDisabledRegister(false);
      } else {
        setIsDisabledRegister(true);
      }
    } catch (err) {
      setIsDisabledRegister(false);
    }
  };

  if (userToVerify) {
    return <CodeVerificationPage usr={userToVerify} onBack={() => navigate('/login')} />;
  }

  return (
    <Container>
      <Row className="panel">
        <Col lg={{ span: 6, offset: 3 }}>
          <Row className="title">
            <Col xs="12">Ingrese sus datos</Col>
            <Col xs="12">
              <label className="required">* Obligatorios</label>
            </Col>
          </Row>

          <Row className="row-data">
            <Col xs="12">
              <FormContainer form={user} onChange={setUser} error={error} labels={formLabels} legends={legends}>
                <FormGroup name="firstName" required />
                <FormGroup name="lastName" required />
                <FormGroup name="username" required noblanks />
                <FormContainerCell cell="6">
                  <FormGroup name="password" type="password" required />
                  <FormGroup name="passwordRepeat" type="password" required />
                </FormContainerCell>
                <FormGroup name="boss" />
                <FormGroup name="email" placeholder="ejemplo@ejemplo.com" required />
                <FormGroup
                  name="phone"
                  placeholder="[Cód.Area + Número de teléfono (sin prefijo 15)] 11 1234-1234"
                  required
                  numeric
                />
              </FormContainer>
            </Col>
          </Row>

          <Row>
            <Col xs="12 button-panel">
              <Button
                variant="secondary"
                type="button"
                onClick={() => {
                  navigate('/login');
                }}
              >
                Ya estoy registrado
              </Button>

              <Button variant="primary" type="button" onClick={goRegister} disabled={isDisabledRegister}>
                Registrarme
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
