/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

export const FormContainer = ({ form, onChange, error, children, labels, legends }) => {
  return (
    <Form>
      {React.Children.map(children, child => React.cloneElement(child, { form, onChange, error, labels, legends }))}
    </Form>
  );
};

export const FormContainerCell = ({ cell, form, onChange, error, children, labels, legends }) => {
  return (
    <Row>
      {React.Children.map(children, child => {
        return <Col xs={cell}>{React.cloneElement(child, { form, onChange, error, labels, legends })}</Col>;
      })}
    </Row>
  );
};

export const FormGroup = ({
  name,
  form,
  onChange,
  error,
  type = 'text',
  required,
  numeric,
  noblanks,
  labels,
  legends = {},
  ...props
}) => {
  const err = error[name];
  const handleChange = value => {
    let valueTransform = value;
    if (numeric) {
      valueTransform = value.replace(/[a-zA-Z ]/g, '');
      valueTransform = valueTransform.replace(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/, '');
    }
    if (noblanks) {
      valueTransform = value.replace(/ /g, '');
    }
    onChange({ [name]: valueTransform });
  };
  return (
    <Form.Group controlId={`formGroup${name}`}>
      <Form.Label>
        {labels[name]}
        {required ? '*' : ''}
      </Form.Label>
      {legends[name] && (
        <Form.Label>
          <small>{legends[name]}</small>
        </Form.Label>
      )}
      <Form.Control
        {...props}
        type={type}
        value={form[name]}
        onChange={evt => handleChange(evt.target.value)}
        isInvalid={!!err}
      />
      {err !== undefined && <Form.Control.Feedback type="invalid">{err}</Form.Control.Feedback>}
    </Form.Group>
  );
};
