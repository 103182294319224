/* eslint-disable no-console */
import _ from 'lodash';
import * as Api from '../services/Api';

import tools from '../utils/tools';
import Event from '../services/Event';
import { dispatch, getState } from './Dispatch';
import * as Auth from './Auth';

const model = 'published';

const ACTIONS = {
  INSERT: `${model}INSERT`,
  INSERT_LAST: `${model}INSERT_LAST`,
  CHANGE_TAG: `${model}CHANGE_TAG`,
  REM_PUBLISHED: `${model}REM_PUBLISHED`,

  INSERT_ALL_COUNT: `${model}INSERT_ALL_COUNT`,
  ADD_COUNT: `${model}ADD_COUNT`
};

export const initialState = {
  [model]: {
    list: {},
    moreCounter: {},
    remListId: {}
  }
};

export const reducer = {
  [Auth.ACTIONS.CLEAN_USER]: state => {
    return { ...state, ...initialState };
  },

  [ACTIONS.INSERT]: (state, { list, destination }) => {
    const data = state[model];

    return {
      ...state,
      [model]: {
        ...data,
        list: { ...data.list, [destination]: list },
        remListId: { ...data.remListId, [destination]: _.first(list)?._id },
        moreCounter: { ...data.moreCounter, [destination]: 0 }
      }
    };
  },

  [ACTIONS.INSERT_LAST]: (state, { list, destination }) => {
    const data = state[model];

    return {
      ...state,
      [model]: {
        ...data,
        list: { ...data.list, [destination]: [...(data.list[destination] || []), ...list] }
      }
    };
  },

  [ACTIONS.REM_PUBLISHED]: (state, { data, destination, _id, child }) => {
    const stateModel = state[model];
    const { list, remListId } = stateModel;

    const filter = ({ data: { _id } }) => _id === data._id;
    const notFilter = ({ data: { _id } }) => _id !== data._id;

    const destinationList = list[destination] || [];

    if (_.findIndex(destinationList, filter) >= 0) {
      if (child) {
        const parent = _.find(destinationList, filter);
        parent.data.children = _.filter(parent.data.children, data => data._id !== child);

        return {
          ...state,
          [model]: {
            ...stateModel,
            remListId: { ...remListId, [destination]: _id }
          }
        };
      }

      return {
        ...state,
        [model]: {
          ...stateModel,
          remListId: { ...remListId, [destination]: _id },
          list: { ...list, [destination]: _.filter(destinationList, notFilter) }
        }
      };
    }

    return {
      ...state,
      [model]: {
        ...stateModel,
        remListId: { ...remListId, [destination]: _id }
      }
    };
  },

  [ACTIONS.CHANGE_TAG]: (state, { contentList, destination }) => {
    const stateModel = state[model];
    const { list } = stateModel;
    const destinationList = list[destination] || [];

    _.each(contentList, content => {
      const parentId = content.parent && content.parent.id;

      const filterParent = ({ data: { _id } }) => _id === (parentId || content._id);
      const filter = ({ _id }) => _id === content._id;

      const parentContent = _.find(destinationList, filterParent);

      if (parentContent) {
        if (parentId) {
          const childContent = _.find(parentContent.data.children, filter);
          if (childContent) {
            childContent.tags = content.tags;
          }
        } else {
          parentContent.data.tags = content.tags;
        }
      }
    });

    return {
      ...state
    };
  },

  [ACTIONS.INSERT_ALL_COUNT]: (state, { moreCounter }) => {
    return {
      ...state,
      [model]: {
        ...state[model],
        moreCounter
      }
    };
  },

  [ACTIONS.ADD_COUNT]: (state, { destination }) => {
    const stateModel = state[model];
    const { moreCounter } = stateModel;

    return {
      ...state,
      [model]: {
        ...stateModel,
        moreCounter: {
          ...moreCounter,
          [destination]: (moreCounter[destination] || 0) + 1
        }
      }
    };
  }
};

const loadMore = async (destination, id) => {
  if (!id) return;
  const size = localStorage.getItem('nextSize');
  const { list } = await Api.findAll('published', { id, destination, size }, { version: 'v2' });
  dispatch({ type: ACTIONS.INSERT_LAST, payload: { list, destination } });
  return list && list.length > 0;
};

const getUnreadList = async destination => {
  try {
    const { list, nextSize, timeLimit } = await Api.findAll('published', { destination }, { version: 'v2' });
    localStorage.setItem('nextSize', nextSize);
    localStorage.setItem('timeLimit', timeLimit);

    dispatch({ type: ACTIONS.INSERT, payload: { list, destination } });
  } catch (err) {
    if (err.message !== 'UnauthorizedException') console.error(err);
  }
};

const addUnread = (data, _id, destinations) => {
  _.each(destinations, destination => dispatch({ type: ACTIONS.ADD_COUNT, payload: { destination } }));
};

const remPublished = (data, destinations, _id, child) => {
  _.each(destinations, destination =>
    dispatch({ type: ACTIONS.REM_PUBLISHED, payload: { data, destination, _id, child } })
  );
};

const update = (statusType, data, _id, destinations) => {
  if (statusType === 'go-unread') {
    _.each(destinations, destination => {
      dispatch({ type: ACTIONS.ADD_COUNT, payload: { destination } });
    });
  }
};

const changeTag = (destination, contentList) => {
  dispatch({ type: ACTIONS.CHANGE_TAG, payload: { contentList, destination } });
};

const syncAfterVisibility = async () => {
  const state = getState();
  const data = state[model];

  const list = [];
  _.each(data.list, (lst, destination) => {
    if (lst) {
      const id = _.first(lst)._id;
      const remId = data.remListId[destination] || id;
      list.push({ destination, id, remId });
    }
  });

  const { result, remList } = await Api.create('published', { list }, { version: 'v2' });

  const moreCounter = _.reduce(
    result,
    (acc, { destination, count }) => {
      acc[destination] = count;
      return acc;
    },
    {}
  );

  dispatch({ type: ACTIONS.INSERT_ALL_COUNT, payload: { moreCounter } });

  _.each(remList, ({ destination, contentId, parentContentId, _id }) =>
    dispatch({
      type: ACTIONS.REM_PUBLISHED,
      payload: {
        data: { _id: parentContentId || contentId },
        destination,
        _id,
        child: parentContentId ? contentId : undefined
      }
    })
  );

  const actualTime = tools.getActualTime();
  const destinationName = state.visibility.destinationVisibleName;
  const lastVisible = state.visibility.lastVisible[destinationName];
  const timeLimit = Number(localStorage.getItem('timeLimit'));

  if (!lastVisible || actualTime - lastVisible >= timeLimit) {
    getUnreadList(destinationName);
  }
};

export default {
  loadMore,
  getUnreadList,
  update,
  addUnread,
  remPublished,
  changeTag
};

Event.on('visibility:on', syncAfterVisibility);
