import * as Api from './Api';

import ContentDestination from '../components/destinations/ContentDestination';

import { views } from '../constants';

const tabs = [
  { title: 'SIECyGCE', Component: ContentDestination, destination: 'SIECyGCE' },
  { title: 'SEPyME', Component: ContentDestination, destination: 'SEPyME' },
  { title: 'SCI', Component: ContentDestination, destination: 'SCI' },
  { title: 'SM', Component: ContentDestination, destination: 'SM' },
  { title: 'SGA', Component: ContentDestination, destination: 'SGA' },
  { title: 'UGA', Component: ContentDestination, destination: 'UGA' }
];

export const getDestinations = async () => {
  return tabs;
};

export const processSearchView = async ({ view: { id, search, destinations } } = {}) => {
  if (!id || id !== views.search) return [];

  const { list } = await Api.findAll('contents', {
    search,
    pagination: false,
    filter: { status: 'Publicado', destinations }
  });

  return list;
};
