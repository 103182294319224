import React from 'react';
import ReactMarkdown from 'react-markdown';

export default props => {
  const InstallationText = () => {
    const text = `La aplicación está disponible tanto para dispositivos Android como iOS, y también se la puede visualizar
        desde un navegador web de PC. Se encuentra a disposición de las y los funcionarios del Ministerio de Desarrollo Productivo.   
        Podés descargar e instalar la app en tu dispositivo con los links que figuran debajo.   
        Una vez instalada, la app solicitará que te registres como usuario/a.  
        Posteriormente, obtendrás la autorización de uso por parte del administrador/a.
        `;

    return <ReactMarkdown>{text}</ReactMarkdown>;
  };

  const InstallationPage = props => {
    return (
      <div className="section background-cyan">
        <div className="title-container">
          <h3 className="title color-white"> INSTALACIÓN</h3>
        </div>
        <div className="install-container">
          <div className="text color-white">
            <InstallationText />
          </div>
          <div className="img-container">
            <img
              alt="Muestra de app en smartphone"
              className="img"
              src={`${props.BASE_PATH_ASSETS}/img/CelularInstalacion.png`}
            />
          </div>
        </div>
        <div className="spacing" />
      </div>
    );
  };

  return <InstallationPage BASE_PATH_ASSETS={props.BASE_PATH_ASSETS} />;
};
