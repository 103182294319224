import React, { useEffect } from 'react';

import './terms.scss';

export default () => {
  /** Reset to the top scroll  */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="legal">
  <h2>Términos y condiciones</h2>
  <h3><a name="_heading=h.30j0zll"></a>
  <font color="#6495ed"><font><font size="3">Alcance</font></font></font></h3>
  <p><font color="#212529"><font>Los
  presentes términos y condiciones  (en adelante, los “Términos y
  Condiciones”) son aplicables a  la relación entre el Ministerio de
  Desarrollo Productivo de la Nación (en adelante, el “Ministerio”)
  y los usuarios de la aplicación denominada INFOPROD (en adelante, los
  “Usuarios”)  tanto en su versión web –accesible a través de
  </font></font><font color="#212529"><font>https://www.infoprod.produccion.gob.ar</font></font><font color="#212529"><font>
  – como en su versión para dispositivos móviles que podrá
  descargarse de las tiendas de aplicaciones oficiales de Android e iOS
  (en adelante, la “Aplicación”).</font></font></p>

  <h3><a name="_heading=h.1fob9te"></a>
  <font color="#6495ed"><font><font size="3">Definiciones</font></font></font></h3>
  <p><font color="#212529"><font><b>Usuarios</b></font></font><font color="#212529"><font>:
  personas humanas con capacidad legal para contratar, que residan en
  la República Argentina, que sean agentes del Ministerio y que
  </font></font><font color="#212529"><font>descarguen,
  se registren y hagan uso de la Aplicación. </font></font>
  </p>

  <p><font color="#212529"><font><b>Administrador:</b></font></font><font color="#212529"><font>
  toda persona humana con capacidad legal para contratar, que resida en
  la República Argentina, que sea funcionaria designada con categoría
  no menor a Directora Nacional dependiente del Ministerio y/o de
  organismos descentralizados dependientes del mismo y que habilite,
  suspenda o cancele el registro de otros usuarios a la Aplicación. </font></font>
  </p>
  <h3><a name="_heading=h.3znysh7"></a>
  <font color="#6495ed"><font><font size="3">Uso
  General</font></font></font></h3>
  <p><font color="#212529"><font>El
  uso de la Aplicación por parte de los Usuarios implica la aceptación
  inmediata y sin reserva de los Términos y Condiciones, de la
  Política de Privacidad y del Aviso Legal. </font></font>
  </p>

  <p><font color="#212529"><font>Para
  utilizar la Aplicación los Usuarios deberán completar un formulario
  de registro, ingresando sus datos personales, manifestar haber leído
  y aceptar en su totalidad los Términos y Condiciones como condición
  indispensable. </font></font>
  </p>

  <p><font color="#212529"><font>Al
  ingresar, los Usuarios deberán acreditar su identidad del modo que
  indique el Ministerio. </font></font>
  </p>
  <p><font color="#212529"><font>El
  Ministerio requerirá a los Usuarios que definan un nombre de usuario
  y contraseña para acceder a la Aplicación. </font></font>
  </p>
  <p><font color="#212529"><font>La
  contraseña podrá ser modificada por los Usuarios y por el
  Administrador. Cada usuario podrá registrarse sólo una vez, salvo
  que el Administrador expresamente autorice un segundo registro. </font></font>
  </p>

  <p><font color="#212529"><font>El
  Ministerio se reserva el derecho de realizar validaciones en relación
  a la información brindada por el usuario al momento del registro. En
  caso de que la información brindada no pueda ser validada, el
  Administrador se reserva el derecho de no aceptar el acceso a dicho
  usuario.
  </font></font>

  </p>
  <p><font color="#212529"><font>El
  Administrador se reserva el derecho de rechazar cualquier solicitud
  de registro, cancelar un registro previamente aceptado o impedir, en
  cualquier momento que un usuario ingrese a la Aplicación siempre que
  tal ingreso pueda poner en riesgo la seguridad de la Aplicación y/o
  que el Usuario haya incumplido los Términos y Condiciones.</font></font></p>


  <p><font color="#212529"><font>Los
  Usuarios que no dispongan de autorización, no podrán acceder a la
  Aplicación. </font></font>
  </p>

  <p><font color="#212529"><font>El
  registro del Usuario y la posterior utilización de la Aplicación es
  gratuita. Sin embargo, los Usuarios son responsables de todos los
  gastos relacionados a la descarga y uso de la Aplicación, incluido
  cualquier cargo de red de operador o itinerancia. En ningún caso el
  Ministerio proveerá a los Usuarios la conectividad necesaria para
  que puedan utilizar la Aplicación.</font></font></p>

  <p><font color="#6495ed"><font><font size="3">Obligaciones
  de los usuarios</font></font></font></p>
  <p><font color="#212529"><font>Los
  Usuarios al aceptar los Términos y Condiciones se obligan a: </font></font>
  </p>
  <ol>
    <li/>
  <p><font color="#212529"><font>suministrar,
    en el proceso de registro, datos personales veraces, completos y
    actualizados. </font></font>
    </p>
    <li/>
  <p><font color="#212529"><font>velar
    por la seguridad de su usuario y contraseña, evitando que terceras
    personas puedan acceder a sus claves. El Ministerio entiende que
    cualquier acción realizada en la Aplicación mediante el nombre de
    un usuario y contraseña elegidos por ese usuario será imputada a
    dicho Usuario. </font></font>
    </p>
    <li/>
  <p><font color="#212529"><font>mantener
    actualizada su información personal. </font></font>
    </p>
    <li/>
  <p><font color="#212529"><font>notificar
    al Administrador ante cualquier uso no autorizado de su clave.</font></font></p>
    <li/>
  <p><font color="#212529"><font>utilizar
    la Aplicación de conformidad con el ordenamiento jurídico vigente
    en la República Argentina y las disposiciones establecidas en los
    Términos y Condiciones. En caso contrario, el Administrador podrá
    suspender su cuenta. </font></font>
    </p>
    <li/>
  <p><font color="#212529"><font>comunicar
    al Administrador toda información a la que tenga acceso que pudiera
    implicar un compromiso a la seguridad de la Aplicación o sus
    contenidos.</font></font></p>
  </ol>
  <p><br/>

  </p>
  <p><font color="#6495ed"><font><font size="3">Prohibiciones</font></font></font></p>
  <p><font color="#212529"><font>Queda
  terminantemente prohibida la realización de las siguientes acciones
  por parte de los Usuarios o del Administrador, con fines distintos a
  los establecidos en los Términos y Condiciones: </font></font>
  </p>
  <ol>
    <li/>
  <p>
    <font color="#212529"><font>copiar, modificar,
    adaptar, traducir, realizar ingeniería inversa, descompilar o
    desensamblar cualquier parte de la Aplicación y de los contenidos
    de la misma. </font></font>
    </p>
    <li/>
  <p>
    <font color="#212529"><font>eludir, desactivar
    o manipular de cualquier otra forma (o tratar de eludir, desactivar
    o manipular) las funciones de seguridad u otras funciones del
    programa.</font></font></p>
    <li/>
  <p>
    <font color="#212529"><font>acceder  a datos
    restringidos o a intentar violar las barreras de seguridad para
    llegar a ellos, realizar búsquedas de vulnerabilidades o
    explotación de las mismas para cualquier fin, ni divulgar
    información acerca de la detección de vulnerabilidades encontradas
    si las hubiere.</font></font></p>
    <li/>
  <p>
    <font color="#212529"><font>utilizar la
    Aplicación o sus contenidos para otros fines, entre otros,
    comerciales, publicitarios, ilícitos. </font></font>
    </p>
    <li/>
  <p>
    <font color="#212529"> <font>usar la
    Aplicación con la finalidad de violar derechos o intereses del
    Ministerio o de terceros.</font></font></p>
    <li/>
  <p>
    <font color="#212529"><font>utilizar la
    Aplicación de manera que altere, dañe o inutilice las redes,
    servidores, equipos, productos y programas informáticos del
    Ministerio o de terceros.</font></font></p>
    <li/>
  <p>
    <font color="#212529"><font>hacer uso del
    contenido en otro sitio web o entorno informático sin la
    autorización previa del Ministerio; </font></font>
    </p>
    <li/>
  <p>
    <font color="#212529"><font>vender, licenciar
    o explotar el contenido y/o cualquier tipo de acceso y/o uso de la
    Aplicación.</font></font></p>
    <li/>
  <p>
    <font color="#212529"><font>incumplir de
    cualquier modo los Términos y Condiciones. </font></font>
    </p>
  </ol>
  <p>
  <br/>

  </p>
  <p><font color="#6495ed"><font><font size="3">Propiedad
  intelectual</font></font></font></p>
  <p><font color="#212529"><font>La
  Aplicación, software, link, diseño gráfico, código fuente,
  expresión de ideas, procedimientos, métodos de operación y
  conceptos matemáticos se encuentran amparados por lo establecido en
  el artículo 1° de la Ley N° 11.723 y sus modificatorias, así sus
  contenidos, marcas, nombres, títulos, designaciones, avisos,
  publicidad, dibujos, diseños, logotipos, textos, fotografías,
  gráficos, imágenes, sonidos, signos distintivos compilaciones de
  datos, entre otros, y todo otro material accesible a través de la
  Aplicación, son de titularidad exclusiva del Ministerio.</font></font></p>
  <p><font color="#212529"><font>El
  Ministerio se reserva todos los derechos sobre la Aplicación y el
  contenido de la misma, no cede, ni transfiere a favor de los Usuarios
  ningún derecho sobre su propiedad intelectual.  </font></font>
  </p>
  <p><font color="#212529"><font>Los
  Usuarios no poseen ningún derecho sobre ellos por el mero uso de la
  Aplicación. </font></font>
  </p>
  <p><font color="#212529"><font>En
  el caso en que la Aplicación permita descargar contenido para su
  posterior lectura por parte de los Usuarios, el Ministerio otorga a
  los Usuarios una licencia de uso gratuita, no transferible, no
  exclusiva y para uso estrictamente definido. </font></font>
  </p>
  <p><br/>

  </p>
  <p><font color="#212529"><font>Los
  Usuarios deberán abstenerse de: </font></font>
  </p>
  <ol>
    <li/>
  <p><font color="#212529"><font>Reproducir,
    copiar, distribuir, poner a disposición de terceros, comunicar
    públicamente, transformar o modificar la Aplicación o sus
    contenidos, salvo en los casos contemplados en la ley o expresamente
    autorizados por el Ministerio o por el titular de dichos derechos. </font></font>
    </p>
    <li/>
  <p><font color="#212529"><font>Reproducir
    o copiar para uso privado la Aplicación o sus contenidos, así como
    comunicarlos públicamente o ponerlos a disposición de terceros
    cuando ello conlleve su reproducción. </font></font>
    </p>
    <li/>
  <p><font color="#212529"><font>Extraer
    o reutilizar todo o parte sustancial de los contenidos integrantes
    de la Aplicación.</font></font></p>
  </ol>
  <p><br/>

  </p>
  <p><font color="#6495ed"><font><font size="3">Responsabilidad
  </font></font></font>
  </p>
  <p><font color="#6495ed"><font><font size="3">	</font></font></font><font color="#212529"><font>Los
  Usuarios son responsables de disponer de herramientas adecuadas para
  la detección y desinfección de programas maliciosos o cualquier
  otro elemento informático dañino que pueda alterar el
  funcionamiento de la Aplicación. </font></font>
  </p>
  <p>
  <font color="#212529"><font>El Ministerio no se
  responsabiliza de los daños producidos a equipos informáticos
  durante el uso de la Aplicación. </font></font>
  </p>
  <p>
  <font color="#212529"><font>El Ministerio no
  será responsable de los daños producidos a los Usuarios cuando
  dichos daños tengan su origen en fallos o desconexiones en las redes
  de telecomunicaciones que interrumpan el servicio.</font></font></p>
  <p>
  <br/>

  </p>
  <p><font color="#212529"><font>En
  la Aplicación podrían encontrarse enlaces a sitios web de terceros.
  En tal caso, el Ministerio no es responsable del contenido de dichos
  sitios web. Además, la existencia de un vínculo entre la Aplicación
  y cualquier sitio de terceros de ninguna manera implica que el
  Ministerio apruebe el contenido de dicho sitio, o, asimismo,
  cualquier uso que dicho contenido pueda tener.</font></font></p>
  <h3><a name="_heading=h.2et92p0"></a>
  <font color="#6495ed"><font><font size="3">Modificación
  de la Aplicación</font></font></font></h3>
  <p><font color="#212529"><font>El
  Ministerio podrá introducir todos los cambios y modificaciones que
  estime convenientes en la Aplicación, lo que incluye pero no se
  limita a agregar, alterar, sustituir o suprimir cualquier contenido
  de la Aplicación en todo momento. </font></font>
  </p>
  <p><br/>

  </p>
  <h3><a name="_heading=h.tyjcwt"></a>
  <font color="#6495ed"><font><font size="3">Operatividad
  de la Aplicación</font></font></font></h3>
  <p><font color="#212529"><font>El
  Ministerio no garantiza el pleno acceso y operatividad de la
  Aplicación en forma ininterrumpida. El Ministerio podrá suspender
  el acceso a la Aplicación y/o a determinado contenido por motivos de
  mantenimiento o de seguridad en cualquier momento. </font></font>
  </p>
  <p><br/>

  </p>
  <h3><a name="_heading=h.3dy6vkm"></a>
  <font color="#6495ed"><font><font size="3">Modificación
  de los Términos y Condiciones</font></font></font></h3>
  <p><font color="#212529"><font>El
  Ministerio se reserva el derecho de modificar los Términos y
  condiciones en cualquier momento.</font></font></p>
  <p><br/>

  </p>
  <p><font color="#6495ed"><font><font size="3">Ley
  aplicable y jurisdicción </font></font></font>
  </p>
  <p><font color="#212529"><font>A
  todos los efectos legales en relación a la Aplicación, será
  aplicable la legislación vigente en la República Argentina. </font></font>
  </p>
  <p><font color="#212529"><font>Cualquier
  controversia que se suscite entre las partes con relación a estos
  Términos y Condiciones, su existencia, validez, calificación,
  interpretación, alcance, cumplimiento o resolución, se resolverá
  definitiva y exclusivamente por los Tribunales Nacionales en lo
  Contencioso Administrativo Federal.</font></font></p>
  
  </div>
  );
};
