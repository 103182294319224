/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import Row from 'react-bootstrap/Row';
import { HashLink as Link } from 'react-router-hash-link';
import { navigate } from '../../services';
import Auth from '../../Store/Auth';
import { Store } from '../../Store';

const FirstPage = props => {
  const [user, setUser] = useState();
  const { dispatch } = useContext(Store);
  useEffect(() => {
    Auth.syncLoggedIn(dispatch)
      .then(res => {
        setUser(res);
      })
      .catch(() => {
        setUser(false);
      });
  }, [dispatch]);

  return (
    <nav className="first-page">
      <Row className="header">
        <div className="logo-container">
          <img alt="logo usinaapp" className="logo" src={`${props.BASE_PATH_ASSETS}/img/Logo.png`} />
        </div>
        <div className="login-container">
          {!user ? (
            <div className="content">
              <button type="button" className="btn" onClick={() => navigate('/register')}>
                REGISTRARSE
              </button>
              <div className="separator"> / </div>
              <button type="button" className="btn" onClick={() => navigate('/login')}>
                INICIAR SESIÓN
              </button>
            </div>
          ) : (
            <div className="content">
              <button type="button" className="btn" onClick={() => navigate('/')}>
                IR A LA HOME
              </button>
            </div>
          )}
        </div>
      </Row>
      <Row className="section">
        <div>
          <img alt="logo usina app" className="top" src={`${props.BASE_PATH_ASSETS}/img/texto-header.png`} />
        </div>
        <div>
          <div className="download-container">
            <img
              alt="boton de descarga"
              className="phone"
              src={`${props.BASE_PATH_ASSETS}/img/CelularInstalacion.png`}
            />
            <Link to="#download-app" className="btn-img bottom">
              <img alt="boton de descarga" className="logo" src={`${props.BASE_PATH_ASSETS}/img/boton-descargar.png`} />
            </Link>
          </div>
        </div>
      </Row>
    </nav>
  );
};

export default props => {
  return <FirstPage BASE_PATH_ASSETS={props.BASE_PATH_ASSETS} />;
};
