/* eslint-disable no-console */
import * as Api from '../services/Api';
import { addNotification } from '../services';

const createEmailCode = async (id, email) => {
  try {
    const res = await Api.publicPost(`v1/code/email/create/${id}`, { email });
    addNotification({ message: res, variant: 'success' });
    return res;
  } catch (err) {
    addNotification({ message: err.business || err.message });
    throw err;
  }
};

const validateEmailCode = async (id, code) => {
  try {
    const res = await Api.publicPost(`v1/code/email/validate/${id}`, { code });
    addNotification({ message: res, variant: 'success' });
    return res;
  } catch (err) {
    addNotification({ message: err.business || err.message });
    throw err;
  }
};

const createSmsCode = async (id, phone) => {
  try {
    const res = await Api.publicPost(`v1/code/sms/create/${id}`, { phone });
    addNotification({ message: res, variant: 'success' });
    return res;
  } catch (err) {
    addNotification({ message: err.business || err.message });
    throw err;
  }
};

const validateSmsCode = async (id, code) => {
  try {
    const res = await Api.publicPost(`v1/code/sms/validate/${id}`, { code });
    addNotification({ message: res, variant: 'success' });
    return res;
  } catch (err) {
    addNotification({ message: err.business || err.message });
    throw err;
  }
};

const createCodeResetPassword = async username => {
  try {
    const res = await Api.publicPost(`v1/code/reset-password/create`, { username });
    addNotification({ message: 'Código enviado al email del usuario.', variant: 'success' });
    return res;
  } catch (err) {
    addNotification({ message: err.business || err.message });
    throw err;
  }
};

const validateCodeResetPassword = async (username, code, password) => {
  try {
    const res = await Api.publicPost(`v1/code/reset-password/validate`, { username, code, password });
    addNotification({ message: res, variant: 'success' });
    return res;
  } catch (err) {
    addNotification({ message: err.business || err.message });
    throw err;
  }
};

export default {
  createEmailCode,
  validateEmailCode,
  createSmsCode,
  validateSmsCode,
  createCodeResetPassword,
  validateCodeResetPassword
};
