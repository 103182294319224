import React from 'react';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';

export default props => {
  const ListCard = props => {
    const { cards } = props;
    const items = _.map(cards, (item, key) => {
      return (
        <li key={key} className="card">
          <img alt="tarjeta ventajas" className="img" src={item.img} />
          <span className="title">
            <ReactMarkdown>{item.publicName}</ReactMarkdown>
          </span>
        </li>
      );
    });
    return <ul className="list-card">{items}</ul>;
  };

  const AdvantagePage = props => {
    const cards = [
      {
        publicName: `Acceso centralizado al   
                    repositorio de informes del MDP`,
        img: `${props.BASE_PATH_ASSETS}/img/icono1.png`
      },
      {
        publicName: `Alertas sobre nuevos   
                    informes y   
                    contenidos`,
        img: `${props.BASE_PATH_ASSETS}/img/icono2.png`
      },
      {
        publicName: `Disponible para   
                    todos los sistemas`,
        img: `${props.BASE_PATH_ASSETS}/img/icono3.png`
      },
      {
        publicName: `Ágil, sencilla y   
                    robusta`,
        img: `${props.BASE_PATH_ASSETS}/img/icono4.png`
      }
    ];

    return (
      <div className="section">
        <div className="title-container">
          <h3 className="title"> VENTAJAS</h3>
        </div>
        <ListCard cards={cards} />
      </div>
    );
  };
  return <AdvantagePage BASE_PATH_ASSETS={props.BASE_PATH_ASSETS} />;
};
