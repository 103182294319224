/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { FormContainer, FormGroup } from '../components/Form';

import { navigate } from '../services';
import AuthAction from '../Store/Auth';

import { validateLogin } from './LoginRegisterValidations';

import CodeVerificationPage from './CodeVerificationPage';

import './LoginRegisterPage.scss';

const labels = {
  username: 'Usuario',
  password: 'Contraseña'
};

const ifEnterDo = action => evt => evt.keyCode === 13 && action();

export default () => {
  const [credential, _setCredential] = useState({});
  const [error, setError] = useState({});
  const [loginError, setLoginError] = useState();

  const [userToVerify, setUserToVerify] = useState();

  const setCredential = useCallback((data = {}) => {
    setError({});
    setLoginError();
    _setCredential(prev => ({ ...prev, ...data }));
  }, []);

  useEffect(() => {
    AuthAction.syncLoggedIn({ stayPage: true });
  }, []);

  const goLogin = async () => {
    const resultValidation = await validateLogin(credential, labels);
    if (_.isEmpty(resultValidation)) {
      try {
        const res = await AuthAction.login(credential);
        if (res.user) {
          if (res.user.firstSession) {
            navigate('/change-password');
          } else {
            navigate('/');
          }
        }
        setLoginError(res);
      } catch (user) {
        setUserToVerify(user);
      }
    } else {
      setError(resultValidation);
    }
  };

  if (userToVerify) {
    return <CodeVerificationPage usr={userToVerify} onBack={() => setUserToVerify()} />;
  }

  return (
    <Container>
      <Row className="panel">
        <Col lg={{ span: 6, offset: 3 }}>
          <Row className="title">
            <Col xs="12">Ingrese su Usuario y Contraseña</Col>
            <Col xs="12">
              <label className="required">* Obligatorios</label>
            </Col>
          </Row>

          <Row className="row-data">
            <Col xs="12">
              <FormContainer form={credential} onChange={setCredential} error={error} labels={labels}>
                <FormGroup name="username" required />
                <FormGroup name="password" type="password" required onKeyDown={ifEnterDo(goLogin)} />
              </FormContainer>
            </Col>
          </Row>

          {loginError && (
            <Row>
              <Col xs="12">
                <Form.Label>{loginError}</Form.Label>
              </Col>
            </Row>
          )}

          <Row>
            <Col xs="12 button-panel">
              <Button
                variant="secondary"
                type="button"
                onClick={() => {
                  navigate('/register');
                }}
              >
                Registrarme
              </Button>

              <Button variant="primary" type="button" onClick={goLogin}>
                Entrar
              </Button>
            </Col>

            <Col xs="12 button-panel">
              <Button
                variant="secondary"
                type="button"
                onClick={() => {
                  navigate('/reset-password');
                }}
              >
                Olvidé mi Contraseña
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
